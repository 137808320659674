$primary-color: #101820;
$primary-color-light: #37536e;
$secondary-color: #f0386b;
$light-background: #f2aa4c;
$light-text: rgb(208, 206, 206);
$text-color: black;

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes zoomInTranslate {
  from {
    opacity: 0;
    transform: scale(0.5);
    transform: translate(-50%, -50%);
  }

  to {
    opacity: 1;
    transform: scale(1);
    transform: translate(-50%, -50%);
  }
}

.cabecera {
  display: flex;
  background-color: $primary-color;
  height: 5vh;
  align-items: center;
  padding: 0 60px;
  justify-content: space-between;
  color: $light-background;

  .grupo {
    display: flex;

    > a > Button {
      color: $light-background;
      margin: 0 15px;

      &:hover {
        color: $primary-color-light;
      }
    }
  }
}

.portada {
  position: relative;
  width: 100%;
  height: 95vh;
  overflow: hidden;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    animation: zoomInTranslate 1.5s ease-out;

    > h1 {
      margin: 0;
    }
  }
}

.mosaico {
  @include flex-column-center;
  color: $primary-color;
  height: 100vh;
  margin: 40px;
  background-color: $light-background;

  .grupo {
    display: flex;
    flex-direction: row;

    > img {
      width: 50%;
      height: 50vh;
      object-fit: cover;
    }
  }

  .item {
    height: 50vh;
    width: 50%;
  }

  .texto {
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .grande {
    margin: 10px 0 40px;
  }

  .pequeño {
    text-align: justify;
    margin: 0 20px;
  }
}

.imagenes {
  @include flex-column-center;
  text-align: center;
  background: linear-gradient(to bottom, $primary-color 50%, transparent 50%);

  .titulo {
    color: $light-background;
    margin: 25px;
  }

  .grid {
    width: 90%;
    margin: 0 5% 5% 5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .item {
    width: 100%;
    height: 27vh;
    cursor: pointer;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  .item:hover {
    transform: scale(1.1);
  }

  .overlay {
    @include center-flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }

  .overlay-content img {
    max-width: 80vw;
    max-height: 80vh;
  }
}

.contacto {
  margin-top: 80px;
  color: $primary-color;
  background-color: $light-background;
  display: flex;
  align-items: center;
  flex-direction: column;

  .titulo {
    display: flex;
    align-items: center;
  }

  .grupoContacto {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;

    > .grupoIzq > div {
      margin-bottom: 40px;
    }

    .contenido {
      margin-left: 20px;
    }

    .campos {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .superior {
        width: 100%;

        > div {
          width: 50%;
          margin: 0;
        }
      }

      .intermedio {
        width: 100%;

        > div {
          width: 100%;
        }
      }

      .inferior {
        width: 100%;

        > div {
          width: 100%;
        }
      }

      > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        > button {
          margin-top: 11.5px;
          color: $light-background;
        }
      }
    }
  }
}

.pie {
  margin-top: 20vh;
  height: 20vh;
  @include flex-column-center;
  background-color: $primary-color;
  text-align: center;
  justify-content: center;
}
